// @import '../node_modules/antd/dist/antd.css';
@import 'assets/scss/global';
@import 'assets/scss/utilities/utilities';

:root {
  --primary: #249f79;
}

body {
  margin: 0;
  padding: 0;
}

.loading.vertical-center {
  height: 50px;
}

.loading.vertical-center .ant-spin {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ant-btn.disabled,
.ant-btn[disabled] {
  background-color: white;
}

.ant-input[disabled] {
  color: #455560;
}

.danger {
  color: red;
}

input:disabled {
  background: #ededed;
  color: #455560;
  border: 1px solid #ebebeb;
}


.ant-select-selection__placeholder {
  color: blue;
}

.ant-radio-inner {
  border-color: #cecdcd;
}

.ant-radio-disabled+span {
  color: #455560;
}

.ant-radio-disabled .ant-radio-inner:after {
  background-color: #249f79;
}

.ant-card-actions {
  border-top: 1px solid #249F79;
  background: #249F79;
}

.ant-collapse {
  background-color: #efefef;
}

.ant-card-bordered {
  border: 1px solid #afafaf;
}

.ant-divider {
  background: #b0abab !important;
}

.ant-list-split .ant-list-item {
  border-bottom: 1px solid #b6bbc1;
}

.ant-input {
  border-color: #dbdbdb;
}

a.ant-typography,
.ant-typography a {
  color: #249f79;
}

a.ant-typography:focus,
.ant-typography a:focus,
a.ant-typography:hover,
.ant-typography a:hover {
  color: #249f79;
  opacity: .9;
}

.ant-upload.ant-upload-drag {
  border-color: #249f79;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #78a899;
}

.ant-collapse-header.ant-collapse-header-collapsible-only {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* height: 82px; */
}

.ant-collapse-header.ant-collapse-header-collapsible-only span svg {
  position: relative;
  bottom: 7px;
}

/**Custom / generic part ***/

.btnHeaderCard {
  margin-right: 25px;
  color: #8b8e8d;
  font-weight: 500;
  padding: 0;
}

.btnHeaderCardActive {
  margin-right: 25px;
  color: #8b8e8d;
  font-weight: 500;
  padding: 0;
  color: #249f79;
}

.btnHeaderCardActive::after {
  content: '';
  border-bottom: 2.7px solid #249f79;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 35px;
  transition: 0.3s;
}

.def-InputBackground {
  background-color: '#f8f9f9';
  margin-top: '7px';
  border-radius: '0.325rem';
}

.def-textStyle {
  font-size: '0.800rem';
}

.ant-btn-primary.warning {
  color: black;
}

.formitem-inline {}

.formitem-inline label {
  padding-top: 10px;
}

/**
 *
 */
.cardContent.no-bg {
  background: none;
  margin: 0;
  border: none;
}

.cardContent.no-bg .ant-card-body {
  padding: 0;
}

.item-treatment {
  //background-color: #fff;
  //padding: 1.25em;
  margin: 30px 0px;
  // border: 1px solid $border-color;
  border-radius: 10px;
}

.item-treatment .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  padding-top: 15px;
}

.item-treatment h4 {
  font-size: 17px;
  margin: 0;
}

.item-treatment:first-child {
  margin-top: 0;
}

.item-treatment:last-child {
  margin-bottom: 0;
}

/**
 * Modal
 **/

/**
 * Modal
 **/

.cardContent {
  margin-bottom: 10px;
  border-radius: 0;
}

.ant-card-head {

  & article.ant-typography.danger {
    color: red;
  }

  & article.ant-typography {
    font-size: 12px;
    font-weight: normal;

    & span {
      padding-right: 8px;
      font-size: 20px;
    }
  }
}

.ant-card-extra {
  display: flex;
}

.ant-card-extra .ant-form-item {
  margin: 0;
}

.ant-modal-body .ant-card-head {
  width: 96%;
}

.ant-modal .ant-modal-close {
  margin-top: 28px;
  margin-right: 25px;
}

.modal-dialog-fullscreen {
  width: 100vh;
  min-width: 95%;
  top: 2vh;
  height: 98vh;
  display: flex;
}

.modal-dialog-mid {
  width: 70vh;
  min-width: 70%;
  top: 2vh;
  height: 70vh;
  display: flex;
}

.ant-modal .ant-card-extra {
  padding-top: 10px;
  padding-bottom: 10px;
}

.modal-dialog-fullscreen .ant-card-no-body .ant-card-body,
.ant-modal .ant-card-no-body .ant-card-body {
  padding-top: 2px;
  padding-bottom: 0;
}

.ant-modal .ant-card-head-title {
  font-weight: bold;
  padding-bottom: 15px;
  padding-top: 15px;
}

.modal-dialog-fullscreen .ant-modal-close {
  // margin-top: 28px;
  // margin-right: 25px;
}

.ant-modal .ant-modal-content,
.modal-dialog-fullscreen .ant-modal-content {
  width: 100%;
}

.ant-modal-content {
  height: 100% !important;
}

.modal-dialog-fullscreen .ant-menu-inline {
  border: none;
}

.modal-dialog-fullscreen .ant-modal-footer {
  display: none;
}

.modal-dialog-fullscreen .ant-modal-content .anticon.iconCheck {
  color: #e4e7e6;
}

.modal-dialog-fullscreen .ant-modal-content .anticon span {
  font-size: 15px;
}

.modal-dialog-fullscreen #inputContent {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(98vh - 175px);
  padding-right: 10px;
}

.modal-dialog-fullscreen .rightSide {}

.modal-dialog-fullscreen .rightSide .background {
  width: 100%;
  padding: 0px 6%;
}

.modal-dialog-fullscreen .tips {
  /* Colors / Shade 01 */
  margin-top: 90px;
  background: #ffffff;
  border: 2px solid #9dd7e7;
  box-sizing: border-box;
  /* _ Shadow / _Graphs */

  box-shadow: 8px 8px 24px rgba(44, 65, 58, 0.04);
  border-radius: 6px;
}

.modal-dialog-fullscreen .tips h5 {
  padding: 32px 0 0 24px;
  color: #262a3e;
}

.modal-dialog-fullscreen .tips article {
  padding: 5px 25px 25px 25px;
  color: #8f9996;
}

/**
 * Popover
 */

.ant-popover-inner-content p {
  padding: 0;
  margin: 0;
}

/**
 * Generic
 */
.text-link {
  cursor: pointer;
}

.maxHeighInh {
  max-height: inherit;
}

.ant-checkbox-inner {
  border: 1px solid #aeaeae;
}

.checkbox-text {
  font-size: 0.9rem;
  font-weight: 500;
}

.bold-text {
  font-size: 0.9rem;
  font-weight: 500;
}

.text-left-margin {
  margin-left: 4px;
}

.gray-text {
  color: #a6adab;
  font-size: 0.9rem;
}

.table-title {
  color: #a6adab;
  font-size: 0.8rem;
  font-weight: 500;
}

.ant-input:placeholder-shown {
  color: #a6adab;
}

.btn-borderless {
  border: none;
  padding: 0;
  border-radius: 0;
}

.btn-borderless:focus {
  border-radius: 0;
  box-shadow: none;
}

.ant-select-selection__placeholder {
  color: black;
}

.ant-select-dropdown,
.ant-picker-dropdown {
  z-index: 1100;
}

.ant-table-thead>tr>th {
  color: #a5adab;
  font-size: 0.775rem;

  .anticon {
    color: #a5adab;

    &.active {
    //   color: $primary;
    }
  }
}

.ant-divider-horizontal {
  margin: 10px 0;
}

.ant-divider {
  background: #f8f8f800;
}

p.linkElement {
//   color: $primary;
  cursor: pointer;
}

.mt-1 {
  margin-top: 0.5em !important;
}

.mt-2 {
  margin-top: 1em !important;
}

.mt-3 {
  margin-top: 1.5em !important;
}

.mt-4 {
  margin-top: 2em !important;
}

.mt-5 {
  margin-top: 2.5em !important;
}

.mr-1 {
  margin-right: 0.5em !important;
}

.mr-2 {
  margin-right: 1em !important;
}

.mr-3 {
  margin-right: 1.5em !important;
}

.mr-4 {
  margin-right: 2em !important;
}

.mr-5 {
  margin-right: 2.5em !important;
}

.ml-1 {
  margin-left: 0.5em !important;
}

.ml-2 {
  margin-left: 1em !important;
}

.ml-3 {
  margin-left: 1.5em !important;
}

.ml-4 {
  margin-left: 2em !important;
}

.ml-5 {
  margin-left: 2.5em !important;
}

.pt-1 {
  padding-top: 0.5em !important;
}

.pt-2 {
  padding-top: 1em !important;
}

.pt-3 {
  padding-top: 1.5em !important;
}

.pt-4 {
  padding-top: 2em !important;
}

.pt-5 {
  padding-top: 2.5em !important;
}

.pr-1 {
  padding-right: 0.5em !important;
}

.pr-2 {
  padding-right: 1em !important;
}

.pr-3 {
  padding-right: 1.5em !important;
}

.pr-4 {
  padding-right: 2em !important;
}

.pr-5 {
  padding-right: 2.5em !important;
}

.pl-1 {
  padding-left: 0.5em !important;
}

.pl-2 {
  padding-left: 1em !important;
}

.pl-3 {
  padding-left: 1.5em !important;
}

.pl-4 {
  padding-left: 2em !important;
}

.pl-5 {
  padding-left: 2.5em !important;
}

.ant-btn-link:hover {
  //background-color: transparent;
  border-radius: 0.625rem;
}

@media (min-width: 992px) {
  .ant-col-lg-12 {
    display: block;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #96969685;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5555556c;
}