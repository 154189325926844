.logo {
    height: $header-height;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    background-color: transparent;
    transition: all .2s ease;

    @media screen and (max-width: 992px) {
        position: relative;
        left: 20px;
    }
}
