.scrollbar-style1::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.scrollbar-style1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.scrollbar-small::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(245, 245, 245, 0.4);
}

.scrollbar-small::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(85, 85, 85, 0.4) #555;
}
